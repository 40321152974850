<template>
  <div class="container-fluid">
    <div class="row mb-3">
      <div class="col my-auto">
        <h4 class="text-primary fw-bold mb-0">
          Website Snippets
          <span class="badge bg-info">BETA</span>
        </h4>
      </div>
    </div>
    <h4 class="card-title fw-bold">Integrating the Health Form</h4>

    <div class="row mb-2">
      <div class="col">
        <pre class="text-danger" id="healthFormToCopy">
&lt;swandoola-health-form clinic="{{
            user.clinic_id
          }}" form-id="1234"&gt;&lt;/swandoola-health-form&gt;</pre
        >
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-auto ms-auto">
        <button
          class="btn btn-sm btn-outline-primary"
          v-clipboard:copy="healthFormStringToCopy"
        >
          <i class="far fa-copy me-2"></i>Copy to clipboard
        </button>
      </div>
    </div>

    <h5 class="card-title">Available Options for Health Forms</h5>

    <p class="card-text mb-2">
      Simply add one of the below configurations to the snippet, being careful
      not to remove or modify other parts of the snippet such as the
      <span class="text-danger">clinic="{{ user.clinic.id }}"</span>
    </p>
    <small class="mb-4">
      Please note there must be a space between properties as per the above
      example
    </small>

    <ul class="list-group-flush mb-4">
      <li
        class="list-group-item mb-1 bg-light"
        style="border-left: 15px solid #03006b"
      >
        <div class="row">
          <div class="col my-auto">
            <p class="text-danger mb-1">form-id="123"</p>
            <small
              >Using one of the IDs available on the
              <a href="/forms">form templates view</a>
              you can display your health form on your website, a client
              registration form is shown first.</small
            >
          </div>
          <div class="col-2 my-auto">
            <button
              class="btn btn-outline-primary btn-sm"
              v-clipboard:copy="formIdToCopy"
            >
              <i class="far fa-copy me-2"></i>Copy to clipboard
            </button>
          </div>
        </div>
      </li>

      <li
        class="list-group-item mb-1 bg-light"
        style="border-left: 15px solid #03006b"
      >
        <div class="row">
          <div class="col my-auto">
            <p class="text-danger mb-1">follow-up-mail-id="123"</p>
            <small
              >Using one of the IDs available on the
              <a href="/marketing/email#templates">marketing email templates</a>
              view, you can automatically send an email to the client, this ID
              represents which email is sent upon submitting the Health
              Form</small
            >
          </div>
          <div class="col-2 my-auto">
            <button
              class="btn btn-outline-primary btn-sm"
              v-clipboard:copy="followUpMailToCopy"
            >
              <i class="far fa-copy me-2"></i>Copy to clipboard
            </button>
          </div>
        </div>
      </li>

      <li
        class="list-group-item mb-1 bg-light"
        style="border-left: 15px solid #03006b"
      >
        <div class="row">
          <div class="col my-auto">
            <p class="text-danger mb-1">form-type="scored"</p>
            <small
              >If this option is set, the form will have a score generation
              feature, after all sections are complete the button can be clicked
              to generate a score based upon <strong>ALL</strong> numbered
              fields in the form. Available options are
              <span class="text-danger">standard</span> and
              <span class="text-danger">scored</span> - default is
              <span class="text-danger">scored</span></small
            >
          </div>
          <div class="col-2 my-auto">
            <button
              class="btn btn-outline-primary btn-sm"
              v-clipboard:copy="formTypeToCopy"
            >
              <i class="far fa-copy me-2"></i>Copy to clipboard
            </button>
          </div>
        </div>
      </li>
    </ul>

    <!--  -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      healthFormStringToCopy: null,
      //
      followUpMailToCopy: 'follow-up-mail-id="123"',
      formTypeToCopy: 'form-type="scored"',
      formIdToCopy: 'form-id="123"',
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    }
  },
  mounted() {
    this.healthFormStringToCopy = document.getElementById(
      "healthFormToCopy"
    ).textContent;
  },
};
</script>

<style>
</style>